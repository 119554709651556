<template>
    <div class="container flex flex-col">
        <Clients
            :search="search"
            :page-size="10"
            :title="title"
            @updated-client-list="updateClients"
        />
    </div>
</template>

<script>
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import Clients from '@/components/clients/Clients'
import { mapState } from 'vuex'

export default {
    name: 'SearchResult',
    mixins: [BackendHelpers, HttpMixin],
    components: { Clients },
    data() {
        const search = this.$route.query.search
        const searchString = search ? search.split(' ') : []
        return {
            searchFirst: searchString[0] ? `%${searchString[0]}%` : null,
            searchLast: searchString[0] ? (searchString[1] ? `%${searchString[1]}%` : `%${searchString[0]}%`) : null,
            loading: false,
            clientList: [],
            showList: true,
            search: search,
        }
    },
    mounted() {
        console.log('search value', this.search)
    },
    methods: {
        async updateClients(clientList) {
            this.loading = false
            this.clientList = clientList
        },
    },
    computed: {
        ...mapState({
            searchText: state => state.app.searchText,
        }),
        title() {
            return `Search results for ${this.search || ''}`
        }
    },
    watch: {
        clientList() {
            this.showList = this.clientList.length > 0
        },
        searchText() {
            if (this.searchText) this.search = this.searchText
        }
    }
}
</script>
